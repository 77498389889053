import classnames from 'classnames';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import React, { ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Modal.module.scss';

type Props = {
    data?: any;
    children: ReactNode;
    title?: string;
    onClose: () => void;
}

const VARIFIDED = true;

export function Modal(props: Props) {

    return (
        <div className={styles['modal']}>
            <div className={styles['wrapper']}>
                <div className={styles['form']}>
                    <div className={styles['cross']} onClick={props.onClose}>
                        <Icon name='cross' />
                    </div>
                    <div className={styles['form__title']}>{props.title}</div>
                    {props.children}
                </div>
            </div>
        </div >
    );
}

