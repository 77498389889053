import { Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { openDemoPopup } from 'redux/rootSlice/rootSlice'
import analytics from './analytics.svg'
import graph from './what206.svg'


type Props = {
}

export function Advisori(props: Props): JSX.Element {
    const dispatch = useDispatch()

    function openReqDemoPopup() {
        dispatch(openDemoPopup())
    }

    return (
        <div className={styles.section} id='advisori'>
            <div className={styles.title3}>
                Crypto Market Strategies
            </div>
            <div className={styles.test2}></div>
            <div className={styles.wrapp}>
                <div className={styles.imgWrapp}>
                    <img src={graph} className={styles.test} />
                </div>
                <div className={styles.contentWrapp}>
                    {/* <div className={styles.title3}>
                        Crypto Market Strategies
                    </div> */}
                    {/* <div className={styles.benefits}>
                        <div className={styles.advantage}>
                            <div className={styles.advantage_title}>Strategic Planning & Consultation</div>
                            <span className={styles.advantage_desc}>Tokenomics, roadmap, and marketing for long-term success</span>
                        </div>
                        <div className={styles.advantage}>
                            <div className={styles.advantage_title}>Go-To-Market Support</div>
                            <span className={styles.advantage_desc}>Market strategy, research, campaigns for optimal engagement</span>
                        </div>
                        <div className={styles.advantage}>
                            <div className={styles.advantage_title}>Partnership Development</div>
                            <span className={styles.advantage_desc}>Alliances, collaborations for successful launch and market presence</span>
                        </div>
                    </div> */}
                    <div className={styles.tagWrapp}>
                        <div className={styles.tag}>Launchpad</div>
                        <div className={styles.tag}>Tokenomics</div>
                        <div className={styles.tag}>Go-To-Market Strategy</div>
                        <div className={styles.tag}>Marketing Research</div>
                        <div className={styles.tag}>Compliance</div>
                        <div className={styles.tag}>Alliances & Partnerships</div>
                    </div>
                </div>
            </div>
        </div >
    )
}