import gate from './logoIcons/gateio.png'
import weex from './logoIcons/weex1.png'
import toko from './logoIcons/tokocrypto.png'
import xt from './logoIcons/xt.png'

export const MarketData = [
    {
        id: 1,
        exchange: 'Binance',
        logo: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.png?v=014',
        pair: 'TOKEN/USDT',
        price: '$0.0001856',
        spread: '0.02%',
        depthPlus: '$254,062',
        depthMinus: '$764,260',
        volume: '$167,000,107',
        volumePercent: '24.06%',
        updated: 'Recently',
        trustScore: 'green'
    },
    {
        id: 2,
        exchange: 'Gate.io',
        logo: gate,
        pair: 'TOKEN/USDT',
        price: '$0.0001856',
        spread: '0.02%',
        depthPlus: '$803,200',
        depthMinus: '$1,513,467',
        volume: '$29,555,646',
        volumePercent: '4.26%',
        updated: 'Recently',
        trustScore: 'green'
    },
    {
        id: 3,
        exchange: 'TokoCrypto',
        logo: toko,
        pair: 'TOKEN/USDT',
        price: '$0.0001841',
        spread: '0.02%',
        depthPlus: '$211,028',
        depthMinus: '$817,314',
        volume: '$409,694',
        volumePercent: '0.06%',
        updated: 'Recently',
        trustScore: 'green'
    },
    {
        id: 4,
        exchange: 'XT.COM',
        logo: xt,
        pair: 'TOKEN/USDT',
        price: '$0.0001841',
        spread: '0.02%',
        depthPlus: '$314,159',
        depthMinus: '$365,121',
        volume: '$8,405,183',
        volumePercent: '1.21%',
        updated: 'Recently',
        trustScore: 'green'
    },
    {
        id: 5,
        exchange: 'WEEX',
        logo: weex,
        pair: 'TOKEN/USDT',
        price: '$0.0001844',
        spread: '0.02%',
        depthPlus: '$269,153',
        depthMinus: '$257,218',
        volume: '$1,514,369',
        volumePercent: '0.22%',
        updated: 'Recently',
        trustScore: 'green'
    }
]