import React from 'react'
import { Button } from 'components/Button/Button'
import styles from './LoginContent.module.scss'
import { useAppDispatch } from 'redux/hooks'
import { fetchLogin } from 'redux/rootSlice/rootSlice'
import { USER_TYPE } from 'redux/consts'
import { Input } from 'components/Input/Input'
import { useNavigate } from 'react-router-dom'


type Props = {
    onClose: () => void,
    onChangeRegister: () => void,
    onChangeResetPassword: () => void,
    disableTitle: boolean,
}

export function LoginContent(props: Props) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [credentials, setCredentials] = React.useState({
        email: '',
        password: '',
    })

    function handleSubmit(event: React.SyntheticEvent<Element, Event>) {
        event.preventDefault();
        dispatch(fetchLogin(credentials))
        navigate('/app')
    }

    function handleChange(target) {
        setCredentials({
            ...credentials,
            [target.name]: target.value,
        })
    }

    console.log('super test', credentials)

    const validLength = credentials.password.length >= 8

    return (
        <form className={styles['form']} onSubmit={handleSubmit}>
            {!props.disableTitle && (
                <span className={styles['title']}>
                    Log in to your personal account
                </span>
            )}
            <Input
                type='mail'
                label='Email'
                name='email'
                value={credentials.email}
                // required={true}
                onChange={handleChange} />
            <Input
                type='password'
                password
                label='Password'
                name='password'
                value={credentials.password}
                // required={true}
                onChange={handleChange} />
            <div className={styles['footer']}>
                <Button
                    type='transparent'
                    behaviourType='submit'
                    onClick={() => { }}
                // disabled={!validLength}
                >
                    Login
                </Button>
                {/* <div className={styles['footer__info']}>
                    <div className={styles['help']} onClick={props.onChangeRegister}>Зарегистрироваться</div>
                    <div className={styles['help']} onClick={props.onChangeResetPassword}>Забыли пароль?</div>
                </div> */}
            </div>
        </form >
    );
}

