import { Button } from 'components/Button/Button'
import styles from './MainOffer.module.scss'
import { useDispatch } from 'react-redux'
import { openDemoPopup } from 'redux/rootSlice/rootSlice'
import analytics from './analytics.svg'
import test3 from './test5.svg'


type Props = {
}

export function MainOffer(props: Props): JSX.Element {
    const dispatch = useDispatch()

    function openReqDemoPopup() {
        dispatch(openDemoPopup())
    }

    return (
        <div className={styles['section']} style={{ position: 'relative', overflow: 'hidden' }}>
            <div className={styles.left} >
                <h1 className={styles['title']}>
                    Unlocking the Power of Crypto Market Making
                </h1>
                <br />
                <p className={styles['section__text']}>
                    Engage market makers and monitor liquidity to maintain a robust market for your token
                </p>
                <div>
                    <Button
                        type='accent'
                        behaviourType='submit'
                        size='l'
                        onClick={openReqDemoPopup}>
                        Request a Demo
                    </Button>
                </div>

            </div>
            <div>
                <img src={test3} alt="Complex SVG" className={styles.img} />
                {/* <img src={analytics} alt="Complex SVG" className={styles.img} /> */}
            </div>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '-1' }}
            >
                <path
                    fill="#f3f4f6"
                    fillOpacity="1"
                    d="M0,128L60,160C120,192,240,256,360,266.7C480,277,600,235,720,197.3C840,160,960,128,1080,138.7C1200,149,1320,203,1380,229.3L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                ></path>
            </svg> */}
        </div>
    )
}