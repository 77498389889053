import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './NavBar.module.scss'
import { cancelAuthAction } from 'redux/rootSlice/rootSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import coin from './coin1.png'
import classnames from 'classnames'


type Props = {
    openLoginPopup: () => void;
    openReqDemoPopup: () => void;
}

export function NavBar(props: Props) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const { session } = useAppSelector((state) => state.root)

    const mail = session ? 'demo@gmail.com' : '-'

    // function navigateTo(link) {
    //     navigate(`/${link}`)
    // }

    function handleLogo() {
        // navigate('/app')
    }

    function userOut() {
        dispatch(cancelAuthAction())
        navigate('/')
        // handleLogo()
    }

    const navHeight = 60

    function scrollTo(id) {
        const element = document.getElementById(id);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - navHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    }

    return (
        <div className={styles['container']}>
            <div className={styles['layout']}>
                <div className={styles['layout__logo']} onClick={handleLogo}>
                    <div className={styles['logo']}>
                        <div className={styles.testcoint}>
                            <img src={coin} alt="Complex SVG" className={styles.coin} />
                        </div>
                        <span className={styles['logoName']}>Master of Coin</span>
                    </div>
                </div>
                <div className={styles['wrapper']}>
                    {!session ? (
                        <>
                            <div
                                className={classnames(styles['layout__sub'], styles['mobile'])}
                                onClick={() => scrollTo('advisori')}>
                                Consulting Services
                            </div>
                            <div
                                className={classnames(styles['layout__sub'], styles['mobile'])}
                                onClick={() => scrollTo('analytics')}>
                                Data Insignts
                            </div>
                            <Button
                                type='transparent'
                                behaviourType='submit'
                                onClick={props.openReqDemoPopup}>
                                Request a Demo
                            </Button>

                            <div
                                className={styles['layout__sub']}
                                onClick={props.openLoginPopup}>
                                Sign In
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={styles['layout__demo']}
                            >
                                $TOKEN
                                {/* TKN */}
                                {/* <Icon name='user' /> */}
                            </div>
                            <div
                                className={classnames(styles[`layout__sub`], styles[`logout`])}
                                onClick={userOut}>
                                <Icon name='exit' />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

