import React from 'react';
import styles from './Footer.module.scss';
import { Icon } from '../../../../components/Icon/Icon';
import { Link } from 'react-router-dom';


type Props = {
    data?: any;
    onClickHelp?: () => void;
}
export function Footer(props: Props): JSX.Element {

    return (
        <div className={styles['footer']}>
            <div className={styles['raw']}>
                <div className={styles['footer__column']}>
                    {/* <a href="/docs/privacy-policy" className={styles['footer__link']}>Пользовательские соглашения</a> */}
                    {/* <a href="/docs/contact" className={styles['footer__link']}>Email</a> */}
                    <div className={styles['icon']}>
                    <svg height="40px" width="40px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve" fill="currentColor" stroke="currentColor" stroke-width="10.24"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css">  </style> <g> <path className="st0" d="M510.678,112.275c-2.308-11.626-7.463-22.265-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345 c-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557,0-37.395,7.996-50.21,20.814c-1.715,1.715-3.301,3.43-4.823,5.345 C8.785,90.009,3.63,100.649,1.386,112.275C0.464,116.762,0,121.399,0,126.087V385.92c0,9.968,2.114,19.55,5.884,28.203 c3.497,8.26,8.653,15.734,14.926,22.001c1.59,1.586,3.169,3.044,4.892,4.494c12.286,10.175,28.145,16.32,45.319,16.32h369.958 c17.18,0,33.108-6.145,45.323-16.384c1.718-1.386,3.305-2.844,4.891-4.43c6.27-6.267,11.425-13.741,14.994-22.001v-0.064 c3.769-8.653,5.812-18.171,5.812-28.138V126.087C512,121.399,511.543,116.762,510.678,112.275z M46.509,101.571 c6.345-6.338,14.866-10.175,24.512-10.175h369.958c9.646,0,18.242,3.837,24.512,10.175c1.122,1.129,2.179,2.387,3.112,3.637 L274.696,274.203c-5.348,4.687-11.954,7.002-18.696,7.002c-6.674,0-13.276-2.315-18.695-7.002L43.472,105.136 C44.33,103.886,45.387,102.7,46.509,101.571z M36.334,385.92V142.735L176.658,265.15L36.405,387.435 C36.334,386.971,36.334,386.449,36.334,385.92z M440.979,420.597H71.021c-6.281,0-12.158-1.651-17.174-4.552l147.978-128.959 l13.815,12.018c11.561,10.046,26.028,15.134,40.36,15.134c14.406,0,28.872-5.088,40.432-15.134l13.808-12.018l147.92,128.959 C453.137,418.946,447.26,420.597,440.979,420.597z M475.666,385.92c0,0.529,0,1.051-0.068,1.515L335.346,265.221L475.666,142.8 V385.92z"></path> </g> </g></svg>
                    </div>
                    <span>info@masterofcoin.io</span>
                </div>
                <div className={styles['footer__column_social']}>
                    <div className={styles['footer__icon']}>
                        <Link to={`https://t.me/subprice_official`} target="_blank" className={styles['social']}>
                            {/* <Icon name="telegram_mini" /> */}
                            {/* <div>Telegram</div> */}
                        </Link>
                    </div>
                    {/* <div className={styles['footer__icon']}> <a href="#" className={styles['footer__link']}> <Icon name="whatsapp" /></a></div> */}
                </div>
            </div>
        </div>
    )
}