import styles from './styles.module.scss'
import { Wrapper } from 'components/Wrapper/Wrapper'
import { MarketTable } from './MarketTable/MarketTable'
import { MarketData } from './data/dataExchange'
import { Chart } from './Chart/Chart'
import { Row } from './Row/Row'


export function Dashboard() {
    return (
        <Wrapper>
            <div className={styles['wrapper']}>
                <div className={styles['content']}>
                    <div className={styles.overview}>
                        <h3 className={styles.title}>Overview</h3>
                        <Row label='Market Cap' value='$1,799,274,36' tooltip='The total market value of a cryptocurrencys circulating supply. It is analogous to the free-float capitalization in the stock market. Market cap = Current price x Circulating supply' />
                        <Row label='Fully Diluted Valuation' value='$1,856,35,934' tooltip='The market cap if the max supply was in circulation. Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply' />
                        <Row label='24 Hour Trading Vol' value='$683,283,13' tooltip='A measure of how much of a cryptocurrency was traded in the last 24 hours.' />
                        <Row label='Total Value Locked' value='$25,636,72' tooltip='Total Value Locked (TVL) refers to the total amount of assets (such as cryptocurrencies) that are currently staked, locked, or deposited in a decentralized finance (DeFi) protocol or platform. It represents the overall liquidity available in the protocol, and its a key metric used to assess the popularity, security, and scale of a DeFi project' />
                        <Row label='Circulating Supply' value='$9,692,089,70,523' tooltip='The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.' />
                        <Row label='Total Supply' value='$10,000,000,000,00' tooltip='Total supply = Total coins created - coins that have been burned (if any) It is comparable to outstanding shares in the stock market.' />
                        <Row label='Max Supply' value='$10,000,000,000,000' tooltip='The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market.' />
                        <div className={styles.sub}>
                            <div className={styles.sub__title}>Official links</div>
                            <div className={styles.sub__wrapper}>
                                <div className={styles.tag}>Website</div>
                                <div className={styles.tag}>GitHub</div>
                            </div>
                        </div>
                        <div className={styles.sub}>
                            <div className={styles.sub__title}>Socials</div>
                            <div className={styles.sub__wrapper}>
                                <div className={styles.tag}>Twitter</div>
                                <div className={styles.tag}>Telegram</div>
                            </div>
                        </div>
                    </div>
                    <Chart />
                </div>
                <div className={styles.table}>
                    <MarketTable data={MarketData} />
                </div>
            </div>
        </Wrapper>
    );
}

