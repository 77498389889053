import gate from 'assets/gateio.png'
import bybit from 'assets/Bybit.png'
import okx from 'assets/okx1.svg'
import kucoin from 'assets/KuCoin.png'
import uniswap from 'assets/Uniswap.png'
import suchi from 'assets/suchi.png'
import bitget from 'assets/bitget1.png'

export const logosUrls = {
    mexc: 'https://seeklogo.com/images/M/mexc-global-logo-FEAF7AE34E-seeklogo.com.png',
    binance: 'https://download.logo.wine/logo/Binance/Binance-Horizontal-Black-Logo.wine.png',
    gate: gate,
    bybit: bybit,
    okx: okx,
    kucoin: kucoin,
    uniswap: uniswap,
    bitget: bitget,
    suchi: suchi,
    dodo: 'https://seeklogo.com/images/D/dodo-logo-40B0E070D4-seeklogo.com.png'
}