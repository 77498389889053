import { Route, Routes, useNavigate } from 'react-router-dom'
import { Layout } from 'layout/Layout'
import { Landing } from 'pages/Landing/Landing'
import { Dashboard } from 'pages/Dashboard/Dashboard'
import { useEffect } from 'react'
import { useAppSelector } from 'redux/hooks'


export function RouterProvider() {
    const { session } = useAppSelector((state) => state.root)
    const navigate = useNavigate()
    useEffect(() => {
        console.log('session', session)
        if (session === null) {
            navigate('/')
        }

    }, [session])

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<Landing />} />
                <Route path="/app" element={<Dashboard />} />
            </Route>
        </Routes >
    );
}