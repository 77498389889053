import styles from './styles.module.scss'
import { TooltipCustom } from 'components/TooltipCustom/TooltipCustom'

type Props = {
    label: string,
    value: string,
    tooltip?: string

}
export function Row(props: Props) {
    return (
        <div className={styles.block}>
            <div className={styles.label}>
                <span>{props.label}</span>
                {props.tooltip && <TooltipCustom text={props.tooltip} />}
            </div>
            <span className={styles.value}>{props.value}</span>
        </div>
    );
}

