import React from 'react'
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './styles.module.scss'
import { logosUrls } from 'pages/Landing/logos'

const companies = [
    { id: 1, logo: logosUrls.binance, name: 'Company 1' },
    { id: 2, logo: logosUrls.gate, name: 'Company 2' },
    // { id: 3, logo: logosUrls.bitget, name: 'Company 3' },
    // { id: 4, logo: 'https://www.modulrfinance.com/hubfs/Modulr%20Website%202024/Client%20Logos/Vyne%20Black.svg', name: 'Company 4' },
    { id: 5, logo: logosUrls.okx, name: 'Company 4' },
    { id: 6, logo: logosUrls.uniswap, name: 'Company 5' },
    { id: 7, logo: logosUrls.kucoin, name: 'Company 66' },
    // { id: 8, logo: logosUrls.gate, name: 'Company 7' },

]

export const CompanyCarousel = () => {
    const settings = {
        infinite: true, // Зацикливание
        slidesToShow: 4, // Количество видимых логотипов
        slidesToScroll: 1, // Сколько логотипов прокручивать
        autoplay: true, // Автоматическая прокрутка
        autoplaySpeed: 0, // Интервал прокрутки
        rtl: false, // Движение справа налево
        speed: 10000, // Скорость прокрутки
        cssEase: "linear" // Плавная анимация
    }

    return (
        <div className={styles.wrapper}>
            <Slider {...settings}>
                {companies.map(company => (
                    <div key={company.id} className={styles.company}>
                        <img src={company.logo} alt={company.name} className={styles.img} />
                    </div>
                ))}
                <div className={styles.company}>
                    <img src={logosUrls.bitget} alt='bitget' className={styles.imgBitget} />
                </div>
            </Slider>
        </div>
    )
}
