import React from 'react'
import styles from './AuthForm.module.scss'
import { LoginContent } from './LoginContent/LoginContent'
import { Button } from 'components/Button/Button'
import { useAppSelector } from 'redux/hooks'


type Props = {
    onClose?: () => void;
    onSubmit?: () => void;
    disableLoginTitle: boolean;
}

export function AuthForm(props: Props) {
    const { session } = useAppSelector((state) => state.root)


    if (session) {
        props.onClose()
        // return (
        //     <div className={styles['loader']}>
        //         <span className={styles['title']}>You have successfully logged in</span>
        //         <Button type='light' behaviourType='submit' onClick={props.onClose}>
        //             Continue
        //         </Button>
        //     </div>
        // )
    }

    return (
        <div className={styles['container']}>
            <LoginContent
                disableTitle={props.disableLoginTitle}
                onClose={() => { }}
                onChangeRegister={() => { }}
                onChangeResetPassword={() => { }}
            />
        </div>
    );
}

