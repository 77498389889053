import React from 'react'
import { Tooltip } from 'react-tooltip'
import styles from './styles.module.scss'
import 'react-tooltip/dist/react-tooltip.css'

type Props = {
    text: string
}
export const TooltipCustom = (props: Props) => {
    return (
        <div className={styles.wrapper}>
            <div
                data-tooltip-id="question-tooltip"
                className={styles.tooltip}
                data-tooltip-content={props.text}// Контент тултипа
            >
                i
            </div>
            <Tooltip
                id="question-tooltip"
                place="top"
                className={styles.customTooltip}
                style={{ padding: '16px', borderRadius: '7px', zIndex: '10' }} />
        </div>
    )
}
