import { AuthForm } from 'components/AuthForm/AuthForm'
import { Modal } from 'components/Modal/Modal'

type Props = {
    onClose?: () => void;
    disableLoginTitle: boolean;
}

export function PopupLogin(props: Props) {
    return (
        <Modal onClose={props.onClose}>
            <AuthForm onClose={props.onClose} disableLoginTitle={props.disableLoginTitle} />
        </Modal>
    );
}

