import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

const SESSION_KEY = 'SESSION'

export const fetchLogin = createAsyncThunk('root/fetchLogin',
    async function (data: { email: string, password: string }, { rejectWithValue }) {
        try {

            const LOGIN_EMAIL = 'demo@gmail.com'
            const LOGIN_PASSWORD = '12345678'

            console.log('data.email', data.email, 'data.password', data.password)

            if (data.email === LOGIN_EMAIL && data.password === LOGIN_PASSWORD) {
                return {
                    token: '73ef97c5-5cd0-471b-9062-ab8d3395775f',
                    mail: LOGIN_EMAIL
                }
            } else {
                return {
                    error: 'Login failed'
                }
            }

        } catch (err) {
            return rejectWithValue({
                error: 'Login failed'
            })
        }
    }
)

export const cancelAuthAction = createAsyncThunk('root/cancelAuth',
    async function (_: void, { dispatch }) {
        try {
            dispatch(cancelAuth())
        }

        catch (err) {
            console.log('fetchLogin/error', err)
            toast.error('Ошибка запроса')
        }
    }
)

interface ErrorPayload {
    error: string
}
export interface RootState {
    isLoading: boolean,
    isDemoPopup: boolean,
    session: string | null,
    mail: string,
    error: string | null
}

const initialState: RootState = {
    isLoading: false,
    isDemoPopup: false,
    mail: null,
    session: sessionStorage.getItem(SESSION_KEY),
    error: null,
}


export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        cancelAuth: (state) => {
            console.log('cancel auth')
            state.session = null;
            sessionStorage.removeItem(SESSION_KEY);
        },
        openDemoPopup: (state) => {
            state.isDemoPopup = true
        },
        closeDemoPopup: (state) => {
            state.isDemoPopup = false
        },
    },
    extraReducers: (builder) => {
        // fetchLogin
        builder.addCase(fetchLogin.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            console.log('test', action.payload)
            sessionStorage.setItem(SESSION_KEY, action.payload.token)
            state.session = sessionStorage.getItem(SESSION_KEY)
            state.mail = action.payload.mail

            state.isLoading = false
        })
        builder.addCase(fetchLogin.rejected, (state, action) => {
            const errorPayload = action.payload as ErrorPayload | undefined
            if (errorPayload && errorPayload.error) {
                state.error = errorPayload.error
            }

            state.isLoading = false
        })
    },
})

export const { cancelAuth, openDemoPopup, closeDemoPopup } = rootSlice.actions

export default rootSlice.reducer