import React from 'react';
import styles from './Wrapper.module.scss';

type Props = {
    children?: React.ReactNode
}

export function Wrapper(props: Props) {
    return (
        <div className={styles['wrapper']}>
            {props.children}
        </div>
    );
}

