import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { AreaChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Switcher } from 'components/Switcher/Switcher'
import dayjs from 'dayjs';
import { TypeChart, TypeInterval, chartDataMap, intervals, typeChartOptions } from './consts'
// import classNames from 'classnames';


export function Chart() {
    const defauultType = TypeChart.SPREAD
    const [filteredData, setFilteredData] = useState<any[]>([]); // Данные для графика
    const [interval, setInterval] = useState<TypeInterval>(TypeInterval.DAY); // Текущий интервал
    const [typeChart, setTypeChart] = useState<TypeChart>(defauultType); // Текущий тип графика

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const { dotLabel, time, bids, asks } = payload[0].payload;
            if (dotLabel) {
                return (
                    <div className={styles.customTooltip}>
                        {/* <div>{`Time: ${dayjs(time).format('HH:mm')}`}</div>
                    <div>{`Bids: ${bids}`}</div>
                    <div>{`Asks: ${asks}`}</div> */}
                        {dotLabel && <div>{`${dotLabel}`}</div>}
                    </div>
                );
            }

            return null;
        }

        return null;
    };
    // Фильтрация данных на основе выбранного интервала
    const filterDataByInterval = (data: any[], interval: TypeInterval) => {
        // Установим временно текущую дату на 2024-07-18 для тестирования
        const now = dayjs('2024-07-18 00:00:00', 'YYYY-MM-DD HH:mm:ss');
        let filteredData = [];

        // console.log('Текущая дата и время для теста:', now.format());

        filteredData = data.filter((item) => {
            const itemDate = dayjs(item.time, 'YYYY-MM-DD HH:mm:ss'); // Парсим время через dayjs
            // console.log('Дата элемента:', item.time, ' -> Парсинг даты:', itemDate.format());

            const diffHours = now.diff(itemDate, 'hour'); // Разница в часах
            // console.log('Разница в часах:', diffHours);

            switch (interval) {
                case TypeInterval.DAY:
                    return diffHours <= 24; // Фильтруем последние 24 часа
                case TypeInterval.WEEK:
                    return diffHours <= 24 * 7; // Фильтруем последние 7 дней
                case TypeInterval.MONTH:
                    return diffHours <= 24 * 30; // Фильтруем последние 30 дней
                default:
                    return true;
            }
        });

        // console.log('Отфильтрованные данные:', filteredData);
        return filteredData;
    }

    // Загружаем данные из заранее импортированного объекта при изменении типа графика
    useEffect(() => {
        const dataForChart = chartDataMap[typeChart] || []
        const filtered = filterDataByInterval(dataForChart, interval)
        setFilteredData(filtered)
    }, [typeChart, interval])


    const renderChart = (type) => {
        if (!filteredData || filteredData.length === 0) {
            return <div className={styles.emptyData}>Coming soon</div>
        }

        switch (type) {
            case TypeChart.PRICE_USDT:
                return (
                    <ResponsiveContainer width="100%" height={400}>
                        <AreaChart data={filteredData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="price" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                );

            case TypeChart.MARKET_CAP:
                return (
                    <ResponsiveContainer width="100%" height={400}>
                        <AreaChart data={filteredData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="marketCap" stroke="#82ca9d" fill="#82ca9d" />
                        </AreaChart>
                    </ResponsiveContainer>
                );

            case TypeChart.SPREAD:
                return (
                    <ResponsiveContainer width='100%' height={400}>
                        <AreaChart data={filteredData}>
                            <defs>
                                <linearGradient id="colorBids" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorAsks" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid
                                horizontal={true}   // Включаем горизонтальные линии
                                vertical={false}    // Отключаем вертикальные линии
                                stroke="#ccc"       // Цвет линий
                                strokeDasharray="3 6" // Пунктирные линии
                                strokeWidth={1}
                            />
                            <XAxis
                                dataKey="time"
                                tick={({ x, y, payload }) => {
                                    const date = dayjs(payload.value)
                                    let formattedValue = ''

                                    if (interval === TypeInterval.DAY) {
                                        formattedValue = date.format('HH:mm'); // Формат только с временем для дневного интервала
                                    } else if (interval === TypeInterval.WEEK) {
                                        formattedValue = date.format('DD.MM'); // День, месяц и время для недельного интервала
                                    } else if (interval === TypeInterval.MONTH) {
                                        formattedValue = date.format('DD.MM'); // Только день и месяц для месячного интервала
                                    }


                                    return (
                                        <text x={x} y={y} className={styles.xdata} dy={16} textAnchor="middle" fill="#918e8e">
                                            {formattedValue}
                                        </text>
                                    )
                                }}
                                interval={Math.max(1, Math.floor(filteredData.length / 7))}
                                axisLine={{ stroke: '#918e8e', strokeWidth: 0.5 }} // Кастомизация осевой линии
                                tickLine={{ stroke: '#918e8e', strokeWidth: 0 }}  // Кастомизация линий меток
                            />
                            <YAxis
                                tick={({ x, y, payload }) => (
                                    <text x={x} y={y} className={styles.xdata} dy={0} textAnchor="end" fill="#918e8e">
                                        {payload.value !== 0 ? payload.value : ''}
                                    </text>
                                )}

                                // domain={['dataMin', 'auto']}
                                // tickFormatter={(value) => (value === 0 ? '' : value)}
                                axisLine={{ stroke: '#918e8e', strokeWidth: 0.5 }} // Кастомизация осевой линии
                                tickLine={{ stroke: '#918e8e', strokeWidth: 0 }}

                            />
                            {/* <Tooltip contentStyle={{ backgroundColor: 'white', borderRadius: '8px', border: '1px solid #ccc', padding: '22px' }} /> */}
                            <Tooltip content={<CustomTooltip />} />
                            <Legend
                                verticalAlign="top"
                                height={36}
                                align="right"
                                iconType="rect"
                                wrapperStyle={{ fontSize: '14px', color: '#555', padding: '0px 0px 10px 16px' }} />
                            <Area
                                // type="monotone"
                                dataKey="bids"
                                stroke="#0673b8"
                                fillOpacity={0.15}
                                strokeWidth={2}
                                fill="url(#colorBids)"
                                // activeDot={{ r: 4 }} // Увеличиваем радиус точки при наведении
                                dot={({ cx, cy, value, payload }) => {
                                    if (payload.showDot) {
                                        return (
                                            <g>
                                                {/* Анимированный пульсирующий круг */}
                                                <circle cx={cx} cy={cy} r={6} stroke="none" fill="#0673b8" opacity={0.5}>
                                                    <animate
                                                        attributeName="r"
                                                        from="5"
                                                        to="15"
                                                        dur="1s"
                                                        repeatCount="indefinite"
                                                        begin="0s"
                                                    />
                                                    <animate
                                                        attributeName="opacity"
                                                        from="0.5"
                                                        to="0"
                                                        dur="1s"
                                                        repeatCount="indefinite"
                                                        begin="0s"
                                                    />
                                                </circle>

                                                {/* Статический круг */}
                                                <circle cx={cx} cy={cy} r={6} stroke="#0673b8" strokeWidth={2} fill="white">
                                                    <title>{payload.dotLabel || `Asks: ${value}`}</title>
                                                </circle>
                                            </g>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            <Area
                                // type="monotone"
                                dataKey="asks"
                                stroke="#2ba550d9"
                                fillOpacity={0.15}
                                strokeWidth={2}
                                fill="url(#colorAsks)"
                                dot={({ cx, cy, value, payload }) => {
                                    if (payload.showDot) {
                                        return (
                                            <g>
                                                {/* Анимированный пульсирующий круг */}
                                                <circle cx={cx} cy={cy} r={6} stroke="none" fill="#2ba550d9" opacity={0.5}>
                                                    <animate
                                                        attributeName="r"
                                                        from="5"
                                                        to="15"
                                                        dur="1s"
                                                        repeatCount="indefinite"
                                                        begin="0s"
                                                    />
                                                    <animate
                                                        attributeName="opacity"
                                                        from="0.5"
                                                        to="0"
                                                        dur="1s"
                                                        repeatCount="indefinite"
                                                        begin="0s"
                                                    />
                                                </circle>

                                                {/* Статический круг */}
                                                <circle cx={cx} cy={cy} r={6} stroke="#2ba550d9" strokeWidth={2} fill="white">
                                                    <title>{payload.dotLabel || `Asks: ${value}`}</title>
                                                </circle>
                                            </g>
                                        );
                                    }
                                    return null;
                                }}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                )

            default:
                return null
        }
    }

    return (
        <div className={styles.wrapp}>
            <div className={styles.filterWrapp}>
                <Switcher
                    data={typeChartOptions}
                    defaultActive={typeChart}
                    onChange={(newTypeChart: TypeChart) => setTypeChart(newTypeChart)}
                />
                <Switcher
                    data={intervals}
                    onChange={(newInterval: TypeInterval) => setInterval(newInterval)}
                />
            </div>
            <div className={styles.content}>
                {renderChart(typeChart)}
            </div>
        </div>
    )
}