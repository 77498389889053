import { Link, Outlet } from 'react-router-dom'
import { Footer } from './components/Footer/Footer'
import styles from './styles.module.scss'
import { MainOffer } from './components/MainOffer/MainOffer'
import { Advisori } from './components/Advisori/Advisori'
// import graph from './marketShareGraph.svg'
// import dop from 'assets/screens/dop.png'
// import catboy from 'assets/screens/CATBOY.png'
// import vanry from 'assets/screens/VANRY.png'
import case1 from 'assets/screens/meme.png'
import case2 from 'assets/screens/meme2.png'
import case3 from 'assets/screens/meme3.png'
import slide11 from 'assets/screens/realtimeusdt.png'
import slide9 from 'assets/screens/slide9.png'
import slide10 from 'assets/screens/slide10.png'

import { ChartDemo } from './components/ChartDemo/ChartDemo'
import { TypeChart, TypeInterval } from './components/ChartDemo/consts'
import { CompanyCarousel } from './components/CompanyCarousel/CompanyCarousel'
import { logosUrls } from './logos'


export function Landing() {
    return (
        <div className={styles['wrapper']}>
            <div className={styles['svg']}>
                <svg id="visual" viewBox="0 0 1440 900" width="1440" height="900" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" style={{ position: 'absolute', top: '0', right: '0', width: '100%', zIndex: '-1' }}><g transform="translate(1594.176544684663 -52.029049023348136)"><path d="M346.8 -497.7C476.2 -383.4 626.1 -317.8 599.5 -233.9C572.9 -150.1 369.6 -48 372.6 176.1C375.5 400.1 584.5 746.1 556.7 857.3C529 968.4 264.5 844.7 3.8 839.5C-256.9 834.2 -513.7 947.4 -692.5 885.3C-871.3 823.3 -972.1 585.9 -870.4 400.5C-768.7 215 -464.6 81.4 -356.3 -73.8C-248.1 -228.9 -335.6 -405.7 -308.9 -553.4C-282.1 -701 -141.1 -819.5 -16.2 -797.2C108.7 -775 217.5 -612 346.8 -497.7" fill="currentColor"></path></g></svg>
            </div>
            <div className={styles['content']}>
                <div className={styles['content__container']}>
                    <MainOffer />
                    <br />
                    <br />
                    <br />
                    <h2 className={styles['title13']} id='analytics'>
                        Our Partners
                    </h2>
                    <CompanyCarousel />

                    <br />
                    <br />
                    <br />
                    <Advisori />
                    <br />
                    <br />
                    <h2 className={styles['title1']} id='analytics'>
                        Data-Driven Insights
                    </h2>
                    <div className={styles.section}>
                        <div className={styles.section_header}>
                            {/* <div className={styles['svg']}>
                                <svg id="visual" viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" style={{ position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '-1' }}><g transform="translate(271.3488168294922 316.69912746373166)"><path d="M195.7 -225C255.1 -183.4 305.8 -123.1 321.5 -53.8C337.3 15.6 318 94 269.3 133.9C220.6 173.7 142.3 175 73.7 197.9C5.1 220.8 -53.9 265.5 -116.4 266C-178.9 266.6 -244.9 223 -263.5 164.8C-282.1 106.5 -253.3 33.4 -241.7 -44.4C-230 -122.2 -235.5 -204.7 -198.1 -250.3C-160.7 -295.8 -80.3 -304.4 -6.1 -297.1C68.1 -289.9 136.3 -266.7 195.7 -225" fill="currentColor"></path></g></svg>
                            </div> */}
                            <div className={styles.title}>
                                Real-time Spread and Depth Monitoring
                            </div>
                            <div className={styles.description}>
                                Track slippage and order book depth for your token across multiple exchanges in real-time, monitor other tokens, and compare liquidity with peers at a glance
                            </div>
                        </div>
                        <div className={styles.graph}>
                            <img src={slide11} alt='graph' className={styles.img} />
                            {/* <ChartDemo interval={TypeInterval.DAY} chart={TypeChart.SPREAD} /> */}
                            {/* <img src={graph} alt='picter' /> */}
                            {/* gateio */}
                        </div>
                    </div>
                    <div className={styles.test44}>
                        <div className={styles.test3}></div>
                        {/* <div className={styles['svg3']}>
                            <svg id="visual" viewBox="0 0 900 600" width="900" height="600" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"><g transform="translate(-180.93386005498562 572.7753319798434)"><path d="M452.1 -490.1C587.5 -425.2 700 -284.2 670 -161.4C640.1 -38.7 467.7 65.9 377.3 213.4C286.8 360.8 278.3 551.1 204.8 589.4C131.3 627.7 -7.2 514 -71.4 401.5C-135.5 289 -125.4 177.8 -118 108.1C-110.6 38.4 -106.1 10.2 -160.5 -88.6C-214.9 -187.4 -328.3 -356.8 -311.3 -442.7C-294.4 -528.5 -147.2 -530.8 5.6 -537.4C158.3 -544 316.7 -555.1 452.1 -490.1" fill="currentColor"></path></g></svg>
                        </div> */}
                        <div className={styles.section}>
                            <div className={styles.section_header}>
                                <div className={styles.title}>
                                    Robust Analytical Expertise
                                </div>
                                <div className={styles.description}>
                                    We are committed to unfaltering transparency, offering real-time data, performance metrics and comprehensive insights through our interactive dashboard
                                </div>
                            </div>
                            <div className={styles.graph}>
                                <img src={slide10} alt='graph' className={styles.img} />
                                {/* <ChartDemo interval={TypeInterval.WEEK} chart={TypeChart.SPREAD} /> */}
                                {/* <img src={graph} alt='picter' /> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.section_header}>
                            <div className={styles.title}>
                                Monitor the Total Value Locked
                            </div>
                            <div className={styles.description}>
                                It is crucial to measures the amount of assets held in a decentralized finance protocol, indicating the platform’s overall liquidity, popularity, and trustworthiness among users
                            </div>
                        </div>
                        <div className={styles.graph}>
                            <img src={slide9} alt='graph' className={styles.img} />
                            {/* <ChartDemo interval={TypeInterval.MONTH} chart={TypeChart.SPREAD} /> */}
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <h2 className={styles['title1']} id='analytics'>
                        Cases
                    </h2>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={styles['case']}>
                        <div className={styles['case__leftside']}>
                            <div className={styles['case__title']}>#1 $MEME</div>
                            <div className={styles['case__content']}>
                                <div className={styles.block}>
                                    {/* <span className={styles.block__title}>Service</span> */}
                                    <span className={styles.block__descriptionMain}>
                                        Blockchain ecosystem needed optimised token liquidation during price spikes and drops
                                    </span>
                                </div>
                                <div className={styles.block}>
                                    <span className={styles.block__title}>MarketCap</span>
                                    <span className={styles.block__description}>$115M | $169M</span>
                                </div>
                                <div className={styles.block}>
                                    <span className={styles.block__title}>Result</span>
                                    <span className={styles.block__description}>
                                        2/3 of holdings was liquidated during the global market growth and was adapted to market corrections after growth
                                    </span>
                                </div>
                            </div>
                            <div className={styles['case__logo']}>
                                <img className={styles['case__miniLogo']} src={logosUrls.binance} alt='logo' />
                            </div>
                        </div>
                        <div className={styles['case__rightside']}>
                            <div className={styles.graph}>
                                <img src={case1} alt='graph' className={styles.img} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={styles['case']}>
                        <div className={styles['case__leftside']}>
                            <div className={styles['case__title']}>#2 $MEME</div>
                            <div className={styles['case__content']}>
                                <div className={styles.block}>
                                    {/* <span className={styles.block__title}>Service</span> */}
                                    <span className={styles.block__descriptionMain}>
                                        Solana native coin needed enhanced token liquidation
                                    </span>
                                </div>
                                <div className={styles.block}>
                                    <span className={styles.block__title}>MarketCap</span>
                                    <span className={styles.block__description}>$51M | $245M</span>
                                </div>
                                <div className={styles.block}>
                                    <span className={styles.block__title}>Result</span>
                                    <span className={styles.block__description}>
                                        20x treasury growth was achieved while maintaining the average weighted price at market levels
                                    </span>
                                </div>
                            </div>
                            <div className={styles['case__logo']}>
                                {/* <img className={styles['case__miniLogo']} src={logosUrls.mexc} alt='logo' /> */}
                                <img className={styles['gate']} src={logosUrls.gate} alt='logo' />
                            </div>
                        </div>
                        <div className={styles['case__rightside']}>
                            <div className={styles.graph}>
                                <img src={case2} alt='graph' className={styles.img} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
             
                    <div className={styles['case']}>
                        <div className={styles['case__leftside']}>
                            <div className={styles['case__title']}>#3 $MEME</div>
                            <div className={styles['case__content']}>
                                <div className={styles.block}>
                                    {/* <span className={styles.block__title}>Service</span> */}
                                    <span className={styles.block__descriptionMain}>
                                        Utility meme token approached us at a $4 price, seeking long-term growth and user acquisition

                                    </span>
                                </div>
                                <div className={styles.block}>
                                    <span className={styles.block__title}>MarketCap</span>
                                    <span className={styles.block__description}>$6M | $30M</span>
                                </div>
                                <div className={styles.block}>
                                    <span className={styles.block__title}>Result</span>
                                    <span className={styles.block__description}>
                                        Profit was doubled from $30k to $60k while maintaining market-making performance and fulfilling all obligations
                                    </span>
                                </div>
                            </div>
                            <div className={styles['case__logo']}>
                                {/* <img className={styles['case__miniLogo']} src={logosUrls.mexc} alt='logo' /> */}
                                {/* <img className={styles['case__miniLogo']} src={logosUrls.gate} alt='logo' /> */}
                            </div>
                        </div>
                        <div className={styles['case__rightside']}>
                            <div className={styles.graph}>
                                <img src={case3} alt='graph' className={styles.img} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
            <Outlet />
            <Footer />
        </div >
    )
}