import React, { useState } from 'react'
import { Icon } from 'components/Icon/Icon'
import styles from './Input.module.scss'
import classNames from 'classnames'

type Props = {
    type: 'text' | 'password' | 'number' | 'mail',
    placeholder?: string,
    label?: string,
    password?: boolean,
    onChange?: (target: HTMLInputElement) => void,
    name?: string,
    value?: string,
    maxWidth?: string
}

export function Input(props: Props): JSX.Element {
    const [inputType, setInputType] = useState(props.type);
    const [isFocused, setIsFocused] = useState(false)
    const [value, setValue] = useState<any>(props.value || '')

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        setValue(newValue)
        if (props.onChange) props.onChange(event.target)
    }

    function handleType() {
        setInputType(inputType === 'password' ? 'text' : 'password')
    }

    function handleFocus() {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className={styles['container']} style={{ maxWidth: `${props.maxWidth}px` }}>
            <div className={styles['inputWrapper']}>
                <input
                    name={props.name}
                    className={styles['input']}
                    placeholder={props.placeholder}
                    type={inputType}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={value}
                />
                {props.password && (
                    <div className={styles['icon']} onClick={handleType}>
                        {inputType === 'password' ? <Icon name='eye' /> : <Icon name='eye_close' />}
                    </div>
                )}
                <label
                    className={classNames(styles['label'], {
                        [styles['labelActive']]: isFocused || value, // Лейбл поднимается при фокусе или наличии текста
                        [styles['labelFocused']]: isFocused // Цвет меняется только при фокусе
                    })}
                >
                    {props.label}
                </label>
            </div>
        </div>
    )
}
