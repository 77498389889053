import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { Input } from 'components/Input/Input';
import { Button } from 'components/Button/Button';

type Props = {
    onClose?: () => void;
}

export function PopupDemo(props: Props) {

    function handleSubmit() {

    }

    return (
        <Modal onClose={props.onClose} title='Request your free demo'>
            <form className={styles['form']} onSubmit={handleSubmit}>
                <div className={styles['content']}>
                    <Input
                        label='First Name'
                        type='text'
                        maxWidth='350'
                        name='name'
                        onChange={() => { }} />
                    <Input
                        label='Last Name'
                        type='text'
                        maxWidth='350'
                        name='lastname'
                        onChange={() => { }} />
                    <Input
                        label='Company Name'
                        type='text'
                        maxWidth='350'
                        name='company'
                        onChange={() => { }} />
                    <Input
                        label='Job Title'
                        type='text'
                        maxWidth='350'
                        name='job'
                        onChange={() => { }} />
                    <Input
                        label='Email'
                        type='text'
                        maxWidth='350'
                        name='email'
                        onChange={() => { }} />
                    <Input
                        label='Mobile Phone Number'
                        type='text'
                        maxWidth='350'
                        name='phone'
                        onChange={() => { }} />
                    <Input
                        label='Country'
                        type='text'
                        maxWidth='350'
                        name='country'
                        onChange={() => { }} />
                </div>
                <Button type='transparent' behaviourType='submit' onClick={() => { }}>
                    Submit
                </Button>
            </form>
        </Modal>
    );
}

