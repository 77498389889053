import { TooltipCustom } from 'components/TooltipCustom/TooltipCustom';
import styles from './styles.module.scss'


type Props = {
    data: any
}

export function MarketTable(props: Props) {
    return (
        <table className={styles.marketTable}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Exchange</th>
                    <th>Pair</th>
                    <th>Price</th>
                    <th>Spread</th>
                    <th>
                        <div className={styles.flex}>
                            +2% Depth <TooltipCustom text='Capital required to increase the price by 2%.' />
                        </div>
                    </th>
                    <th>
                        <div className={styles.flex}>
                            -2% Depth <TooltipCustom text='Capital required to reduce the price by 2%.' />
                        </div>
                    </th>
                    <th>24h Volume</th>
                    <th>Volume %</th>
                    <th>Last Updated</th>
                    <th>
                        <div className={styles.flex}>
                            Trust Score <TooltipCustom text='Algorithmically compares the Reported Volume of exchanges against our data model. The Confidence Indicator indicates the level of confidence we have that the Reported Volume is accurate.' />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((row) => (
                    <tr key={row.id}>
                        <td>{row.id}</td>
                        <td>
                            {row.logo && <img src={row.logo} alt={row.exchange} className={styles.exchangeLogo} />}
                            {row.exchange}
                        </td>
                        <td>{row.pair}</td>
                        <td>{row.price}</td>
                        <td>{row.spread}</td>
                        <td>{row.depthPlus}</td>
                        <td>{row.depthMinus}</td>
                        <td>{row.volume}</td>
                        <td>{row.volumePercent}</td>
                        <td>{row.updated}</td>
                        <td className={styles.wrapp}>
                            <span className={`${styles.trustScore} ${styles[row.trustScore]}`}></span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

