import SPREAD_DATA from '../data/SPREAD_DATA.json'

export enum TypeInterval {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH'
}

export enum TypeChart {
    PRICE_USDT = 'PRICE_USDT',
    MARKET_CAP = 'MARKET_CAP',
    VALUE_LOCKED = 'VALUE_LOCKED',
    SPREAD = 'SPREAD',
    INEFFICIENT = 'INEFFICIENT'
}

export const intervals = [
    { name: TypeInterval.DAY, label: '24h' },
    { name: TypeInterval.WEEK, label: '7d' },
    { name: TypeInterval.MONTH, label: '1m' },
]

export const typeChartOptions = [
    { name: TypeChart.SPREAD, label: 'Bid Ask Spread' },
    { name: TypeChart.PRICE_USDT, label: 'Price to USDT' },
    { name: TypeChart.MARKET_CAP, label: 'Market Cap' },
    { name: TypeChart.VALUE_LOCKED, label: 'Total Value Locked' },
    { name: TypeChart.INEFFICIENT, label: 'Market Inefficiencies' },
]

export const chartDataMap = {
    [TypeChart.PRICE_USDT]: null,
    [TypeChart.MARKET_CAP]: null,
    [TypeChart.SPREAD]: SPREAD_DATA,
    [TypeChart.VALUE_LOCKED]: null,
    [TypeChart.INEFFICIENT]: null,
};