import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './Layout.module.scss'
import { useAppSelector } from 'redux/hooks'
import { NavBar } from './NavBar/NavBar'
import { PopupLogin } from './PopupLogin/PopupLogin'
import { ToastContainer, Flip } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PopupDemo } from './PopupDemo/PopupDemo'
import { useDispatch } from 'react-redux'
import { closeDemoPopup, openDemoPopup } from 'redux/rootSlice/rootSlice'


export function Layout() {
    const [showLoginPopup, setShowLoginPopup] = React.useState(false)

    const { isDemoPopup } = useAppSelector((state) => state.root)
    const dispatch = useDispatch()

    function closePopupCreateSub() {
        setShowLoginPopup(false)
    }

    function closeReqDemoPopup() {
        dispatch(closeDemoPopup())
    }

    function openPopupCreateSub() {
        setShowLoginPopup(true)
    }

    function openReqDemoPopup() {
        dispatch(openDemoPopup())
    }

    return (
        <div className={styles['wrapper']}>
            <div className={styles['layout']}>
                <NavBar
                    openLoginPopup={openPopupCreateSub}
                    openReqDemoPopup={openReqDemoPopup}
                />
            </div>
            {showLoginPopup && <PopupLogin disableLoginTitle={false} onClose={closePopupCreateSub} />}
            {isDemoPopup && <PopupDemo onClose={closeReqDemoPopup} />}
            <ToastContainer
                position="bottom-right"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
                transition={Flip}
            />
            <Outlet />
        </div>
    );
}

