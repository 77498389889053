import { useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

type Props = {
    data: { name: string; label: string; icon?: JSX.Element }[],
    onChange: (name: string) => void,
    defaultActive?: string
}

export const Switcher = ({ data, onChange, defaultActive }: Props) => {
    const [activeItem, setActiveItem] = useState<string>(defaultActive || data[0]?.name)

    const handleClick = (name: string) => {
        setActiveItem(name)
        onChange(name)
    }

    return (
        <div className={styles.switcher}>
            {data.map((item) => (
                <div
                    key={item.name}
                    className={classNames(styles.item, { [styles.active]: item.name === activeItem })}
                    onClick={() => handleClick(item.name)}
                >
                    {item.icon && <span className={styles.icon}>{item.icon}</span>}
                    {item.label}
                </div>
            ))}
        </div>
    );
};