import React, { ReactNode } from 'react'
import classnames from 'classnames'
import styles from './Button.module.scss'

type Props = {
    children: ReactNode;
    type: 'dark' | 'light' | 'accent' | 'transparent' | 'blue'
    onClick?: () => void;
    behaviourType?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    size?: 'l',
    width?: number | string
};

export function Button(props: Props): JSX.Element {
    const buttonClassNames = classnames(
        styles['button'],
        styles[`button_${props.type}`],
        styles[`button_${props.size}`],
    )

    return (
        <button
            type={props.behaviourType}
            className={buttonClassNames}
            style={{ width: props.width }}
            onClick={props.onClick}
            disabled={props.disabled}>
            {props.children}
        </button>
    )
}